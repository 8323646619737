import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { snackActions } from 'generic/utils/snackbar';

import { Grid } from '@mui/material';

import { refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import WidgetContainer from 'generic/containers/WidgetContainer';
import Bar from 'generic/components/dashboard-items/Bar';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import Pie from 'generic/components/dashboard-items/Pie';
import Datatable from 'generic/components/dashboard-items/Datatable';
import { getColorForFrequency, getRandomColor, monoColors } from 'generic/utils/colorUtils';
import THEME_CONFIG from 'generic/core/theme';
import { getFieldIdFromFormFields, getValueOrFirstValueFromArray } from 'generic/utils/qesUtils';

const { entitiesColors, relationsEntitiesColors } = THEME_CONFIG.HIGHCHARTS;

const WORDCLOUD_CONCEPTS = {
  'QES_Company.verbatim': { label: 'companies' },
  'QES_Person.verbatim': { label: 'persons' },
  'QES_Organization.verbatim': { label: 'organizations' },
  'QES_Event.verbatim': { label: 'events' },
  'QES_Product.verbatim': { label: 'products' },
  'QES_ConceptRisk.verbatim': { label: 'risks' },
  'QES_StixThreatActor.verbatim': { label: 'threat_actors' },
  'QES_StixAttackPattern.verbatim': { label: 'attack_patterns' },
  'QES_StixMalware.verbatim': { label: 'malwares' },
  'QES_StixTool.verbatim': { label: 'tools' },
  QES_Email: { label: 'emails' },
};

const DATATABLE_CONCEPTS = {
  'QES_StixObservedData.verbatim': { title: 'observed_data' },
  'QES_Ipv4.verbatim': { title: 'ipv4' },
};

let cooccurrenceFields = [
  { value: 'QES_StixThreatActor.verbatim', name: 'Threat Actors' },
  { value: 'QES_StixAttackPattern.verbatim', name: 'Attack Patterns' },
  { value: 'QES_StixMalware.verbatim', name: 'Malwares' },
  { value: 'QES_StixTool.verbatim', name: 'Tools' },
  { value: 'QES_Ipv4.verbatim', name: 'Ipv4' },
  { value: 'QES_Person.verbatim', name: 'Person' },
  { value: 'QES_Company.verbatim', name: 'Company' },
  { value: 'QES_Organization.verbatim', name: 'Organization' },
  { value: 'QES_ConceptRisk.verbatim', name: 'ConceptRisk' },
].map((field, index) => ({
  ...field,
  color: entitiesColors[index],
}));

let relationsNodesFields = [
  { value: 'Organonoff', name: 'Organonoff' },
  { value: 'Organization', name: 'Organization' },
  { value: 'Product', name: 'Product' },
  { value: 'Person', name: 'Person' },
  { value: 'Concept', name: 'Concept' },
  { value: 'Coldconcept', name: 'Coldconcept' },
  { value: 'Location', name: 'Location' },
  { value: 'Company', name: 'Company' },
  { value: 'Phone', name: 'Phone' },
  { value: 'Event', name: 'Event' },
  { value: 'Unlocalized', name: 'Unlocalized' },
  { value: 'Media', name: 'Media' },
  { value: 'Email', name: 'Email' },
  { value: 'Address', name: 'Address' },
  { value: 'JobTitle', name: 'Job Title' },
].map((field, index) => ({
  ...field,
  color: relationsEntitiesColors[index],
}));

let relationsLinksFields = [
  { name: 'Aim', value: 'Aim' },
  { name: 'Alert', value: 'Alert' },
  { name: 'Appoint', value: 'Appoint' },
  { name: 'Attack', value: 'Attack' },
  { name: 'Develop', value: 'Develop' },
  { name: 'Help', value: 'Help' },
  { name: 'Identification', value: 'Identification' },
  { name: 'Include', value: 'Include' },
  { name: 'Located', value: 'Located' },
  { name: 'Participate', value: 'Participate' },
  { name: 'Protect', value: 'Protect' },
  { name: 'Provide', value: 'Provide' },
  { name: 'Report', value: 'Report' },
  { name: 'Test', value: 'Test' },
  { name: 'Supply', value: 'Supply' },
  { name: 'Support', value: 'Support' },
  { name: 'Use', value: 'Use' },
  { name: 'Job Title', value: 'JobTitle' },
].map((field, index) => ({
  ...field,
  color: getRandomColor(index),
}));

const computeTitle = (title, serie) => {
  let finalTitle = title || '';
  const { totalUnique } = getValueOrFirstValueFromArray(serie);
  if (totalUnique) {
    finalTitle = `${finalTitle} (${totalUnique})`;
  }

  return finalTitle;
};

const DashboardChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const fields = useSelector((state) => state.config.activeBase.champs);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // On utilise ici des refs pour pouvoir les mettre à jour
  // depuis les widgets "enfants" sans re-render tout le
  // dashboard
  const cooccurrencesNodesFilterValue = useRef([
    'QES_StixThreatActor.verbatim',
    'QES_StixAttackPattern.verbatim',
    'QES_StixMalware.verbatim',
    'QES_StixTool.verbatim',
    'QES_Ipv4.verbatim',
  ]);
  const relationsLinksFilterValue = useRef(_.map(relationsLinksFields, 'value'));
  const relationsNodesFilterValue = useRef(_.map(relationsNodesFields, 'value'));

  const buildNodesTypesAdditionalQuery = () => {
    const nodesTypesSources = [];
    const nodesTypesDestinations = [];
    _.forEach(
      relationsNodesFilterValue.current,
      (nodeTypeValue) => {
        nodesTypesSources.push(
          `QES_Relation_Source_Type:${nodeTypeValue}`,
        );
        nodesTypesDestinations.push(
          `QES_Relation_Destination_Type:${nodeTypeValue}`,
        );
      },
    );
    let additionalQuery = '';
    if (nodesTypesSources) {
      additionalQuery = `(${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})`;
    }
    return additionalQuery;
  };

  const handleRefreshRelationChart = (nodesTypesValues, linksTypesValues) => {
    if (_.isEmpty(nodesTypesValues) || _.isEmpty(linksTypesValues)) {
      snackActions.warning(t('dashboard.widget.at_least_one_link_or_node'));
      return;
    }
    relationsNodesFilterValue.current = nodesTypesValues;
    relationsLinksFilterValue.current = linksTypesValues;
    const joinedTypesValue = linksTypesValues.join(',');

    const params = {
      facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
      relations: joinedTypesValue,
      aggregates: [joinedTypesValue],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [joinedTypesValue],
      additionalQuery: buildNodesTypesAdditionalQuery(),
      type: 'networkgraph',
    };
    const keepWidgets = true;
    dispatch(fetchWidgets(strategy, {
      relations: params,
    }, keepWidgets));
  };

  const handleRefreshCooccurrencesChart = (nodesTypesValues) => {
    if (_.isEmpty(nodesTypesValues)) {
      snackActions.warning(t('dashboard.widget.at_least_or_node'));
      return;
    }
    cooccurrencesNodesFilterValue.current = nodesTypesValues;
    const joinedValue = nodesTypesValues.join(',');
    const keepWidgets = true;
    dispatch(fetchWidgets(strategy, {
      cooccurrences: {
        facets: joinedValue,
        aggregates: [joinedValue],
        facetmax: 200,
        facetmax2: 5,
        mindoccount: 1,
        seriesNames: [joinedValue],
        type: 'networkgraph',
      },
    }, keepWidgets));
  };

  const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');
  const relationsLinksFilterValueJoined = relationsLinksFilterValue.current.join(',');
  const widgets = {
    countryTopic: {
      facets: 'QES_CountryCode.verbatim',
      aggregates: ['agg_QES_CountryCode.verbatim'],
      type: 'map',
      mindoccount: 1,
      facetmax: 10000,
      facetmax2: 10000,
      totalunique: true,
    },
    locations: {
      facets: 'QES_Location.verbatim',
      aggregates: ['agg_QES_Location.verbatim'],
      seriesNames: [t('dashboard.widget.places_mentionned')],
      type: 'bar',
      totalunique: true,
    },
    extensions: {
      facets: 'PJ_Extension',
      aggregates: ['agg_PJ_Extension'],
      type: 'pie',
      totalunique: true,
    },
    langs: {
      facets: 'PJ_Langue',
      aggregates: ['agg_PJ_Langue'],
      type: 'pie',
      totalunique: true,
    },
    concepts: {
      facets: _.keys(WORDCLOUD_CONCEPTS).join(';'),
      aggregates: _.keys(WORDCLOUD_CONCEPTS).map((c) => `agg_${c}`),
      seriesNames: _.map(WORDCLOUD_CONCEPTS, 'label'),
      facetmax: 50,
      facetmax2: 3,
      type: 'wordcloud',
      splitPerSerie: true,
      totalunique: true,
    },
    datatablesConcepts: {
      facets: _.keys(DATATABLE_CONCEPTS).join(';'),
      aggregates: _.keys(DATATABLE_CONCEPTS).map((c) => `agg_${c}`),
      seriesNames: _.map(DATATABLE_CONCEPTS, 'title'),
      facetmax: 1000,
      facetmax2: 3,
      splitPerSerie: true,
      type: 'datatable',
      totalunique: true,
    },
    cooccurrences: {
      facets: cooccurrencesNodesFilterValueJoined,
      aggregates: [cooccurrencesNodesFilterValueJoined],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [cooccurrencesNodesFilterValueJoined],
      type: 'networkgraph',
    },
    relations: {
      facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
      relations: relationsLinksFilterValueJoined,
      aggregates: [relationsLinksFilterValueJoined],
      facetmax: 200,
      facetmax2: 100,
      mindoccount: 1,
      seriesNames: [relationsLinksFilterValueJoined],
      additionalQuery: buildNodesTypesAdditionalQuery(),
      type: 'networkgraph',
    },
  };
  const handleExportWidget = (widgetId, facet) => {
    const keepWidgets = true;
    const params = widgets[widgetId];
    if (facet) {
      params.facets = facet;
    }
    params.facetmax = 100000;
    params.exportWidget = true;
    dispatch(fetchWidgets(strategy, {
      widgetId: params,
    }, keepWidgets));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      dispatch(fetchWidgets(strategy, widgets));
    }

  // On ne met pas cooccurrencesNodesFilterValue ni relationsLinksFilterValue dans les dépendances,
  // pour éviter de refresh tout le dashboard quand on change juste le type
  // des éléments affichés (refresh gérés dans handleChangeCooccurrenceValue
  // et handleChangeRelationFieldValue).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy, dispatch]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
    >
      <Grid item xs={12} md={6}>
        <WidgetContainer widgetId="countryTopic">
          {({ series: countryTopicSerie, height, additionalData }) => (
            <DashboardWidget
              height={height}
              component={Map}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      tooltip: {
                        pointFormatter: function getTranslatedCountryName() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                        },
                      },
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              {
                                champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                strategie: event.point.options.name,
                                dontQuoteStrategy: true,
                              },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  series: countryTopicSerie,
                  title: {
                    text: computeTitle(t('dashboard.widget.countries'), countryTopicSerie),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <WidgetContainer widgetId="locations">
          {({ series: locationsSerie, height, additionalData }) => (
            <DashboardWidget
              height={height}
              component={Bar}
              handleExportWidget={() => handleExportWidget('locations')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  tooltip: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              {
                                champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                strategie: event.point.name,
                              },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  legend: false,
                  title: {
                    text: computeTitle(t('dashboard.widget.locations'), locationsSerie),
                    align: 'left',
                  },
                  yAxis: {
                    title: {
                      text: t('dashboard.widget.values'),
                    },
                  },
                  series: locationsSerie,
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="extensions">
          {({ series: extensionsSerie, height, additionalData }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('extensions')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  colors: monoColors(0.35, _.get(extensionsSerie, '[0].data.length', 10), 0),
                  plotOptions: {
                    pie: {
                      data: _.get(extensionsSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              {
                                champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                strategie: event.point.strategy,
                              },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: computeTitle(t('dashboard.widget.files_extensions'), extensionsSerie),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="langs">
          {({ series: langsSerie, height, additionalData }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('langs')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  colors: monoColors(0.35, _.get(langsSerie, '[0].data.length', 10), 0),
                  plotOptions: {
                    pie: {
                      data: _.get(langsSerie, '[0].data', []).map((l) => ({
                        ...l,
                        name: t(`dashboard.languages.${l.strategy}`).split(';')[0],
                      })),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              {
                                champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                strategie: event.point.strategy,
                              },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: computeTitle(t('dashboard.widget.files_languages'), langsSerie),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {_.entries(WORDCLOUD_CONCEPTS).map(([fieldName, fieldDefinition]) => (
        <Grid item xs={12} sm={4} key={`concepts${fieldName}`}>
          <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
            {({ series: conceptsSerie, height }) => (
              <DashboardWidget
                height={height}
                component={Wordcloud}
                handleExportWidget={() => handleExportWidget('concepts', fieldName)}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      wordcloud: {
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, fieldName),
                                  strategie: event.point.options.name,
                                },
                              ], (event.ctrlKey || event.metaKey)));
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      data: _.map(conceptsSerie.data, ([word, weight]) => ({
                        name: word,
                        weight,
                        color: getColorForFrequency(0.35, conceptsSerie.minFreq, conceptsSerie.maxFreq, weight, 0),
                      })),
                      name: t(`dashboard.widget.${conceptsSerie.name}`),
                    }],
                    title: {
                      text: computeTitle(t(`dashboard.widget.${conceptsSerie.name}`), conceptsSerie),
                      align: 'left',
                    },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      ))}

      {
        _.entries(DATATABLE_CONCEPTS).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={4} key={`datatablesConcepts${fieldName}`}>
            <WidgetContainer widgetId={`datatablesConcepts_${fieldDefinition.title}`}>
              {({ series: datatableSerie, height }) => (
                <DashboardWidget
                  component={Datatable}
                  handleExportWidget={() => handleExportWidget('datatablesConcepts', fieldName)}
                  componentProps={{
                    title: {
                      text: computeTitle(t(`dashboard.widget.${fieldDefinition.title}`), datatableSerie),
                      align: 'left',
                    },
                    columns: [
                      { name: '' },
                      { name: t('dashboard.widget.count') },
                    ],
                    onRowClick: (event, rowData) => dispatch(refineAddFacetsValues([
                      {
                        champ: getFieldIdFromFormFields(fields, fieldName),
                        strategie: rowData[0],
                      },
                    ], (event.ctrlKey || event.metaKey))),
                    data: datatableSerie.data,
                  }}
                  height={height}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="cooccurrences" height={700}>
          {({ series: cooccurrenceItem, height, additionalData }) => {
            cooccurrenceFields = cooccurrenceFields.map(
              (cooccurrenceField) => ({
                ...cooccurrenceField,
                nbNodes: _.get(additionalData.nodeCounts, cooccurrenceField.value, 0),
              }),
            );
            return (
              <DashboardWidget
                height={height}
                component={NetworkGraph}
                componentProps={{
                  nodesFilterValue: cooccurrencesNodesFilterValue.current,
                  nodesFilterItems: cooccurrenceFields,
                  handleRefreshChart: handleRefreshCooccurrencesChart,
                  handleLinkClick: (event, fromNode, toNode) => {
                    const fromIdField = getFieldIdFromFormFields(fields, fromNode.options.group);
                    const toIdField = getFieldIdFromFormFields(fields, toNode.options.group);
                    const facetsValues = [{
                      champ: fromIdField,
                      strategie: fromNode.id,
                    }];
                    if (fromIdField === toIdField) {
                      // Si jamais l'ID de champ est le même pour les deux noeuds,
                      // on colle les deux valeurs dans un même tableau, qui sera
                      // découpé convenablement au moment du fetch des résultats
                      facetsValues[0].strategie = [fromNode.id, toNode.id];
                    } else {
                      // A l'inverse, si les ID de champs sont différents, on
                      // on ajoute une seconde "facetValue" avec les éléments
                      // du second noeud
                      facetsValues.push({
                        champ: toIdField,
                        strategie: toNode.id,
                      });
                    }
                    dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                  },
                }}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      series: {
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, event.point.options.group),
                                  strategie: event.point.name,
                                },
                              ], (event.ctrlKey || event.metaKey)));
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      nodes: _.get(cooccurrenceItem, '[0].nodes', []).map(
                        (node) => ({
                          ...node,
                          color: _.find(cooccurrenceFields, { value: node.group })?.color,
                        }),
                      ),
                      data: _.get(cooccurrenceItem, '[0].data', []),
                    }],
                    title: {
                      text: t('dashboard.widget.cooccurrences_graph'),
                      align: 'left',
                    },
                  },
                }}
              />
            );
          }}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="relations" height={700}>
          {({ series: relations, height, additionalData }) => {
            relationsLinksFields = relationsLinksFields.map(
              (relationsLinksField) => ({
                ...relationsLinksField,
                nbLinks: _.get(additionalData.linkCounts, relationsLinksField.value, 0),
              }),
            );
            relationsNodesFields = relationsNodesFields.map(
              (relationsNodesField) => ({
                ...relationsNodesField,
                nbNodes: _.get(additionalData.nodeCounts, relationsNodesField.value, 0),
              }),
            );
            return (
              <DashboardWidget
                height={height}
                component={NetworkGraph}
                componentProps={{
                  linksFilterValue: relationsLinksFilterValue.current,
                  linksFilterItems: _.orderBy(
                    relationsLinksFields,
                    ['nbLinks', (item) => relationsLinksFilterValue.current.indexOf(item.value) > -1, 'name'],
                    ['desc', 'desc', 'asc'],
                  ),
                  nodesFilterValue: relationsNodesFilterValue.current,
                  nodesFilterItems: _.orderBy(
                    relationsNodesFields,
                    ['nbNodes', 'name'],
                    ['desc', 'asc'],
                  ),
                  handleRefreshChart: handleRefreshRelationChart,
                  handleLinkClick: (event, fromNode, toNode) => {
                    const fromIdField = getFieldIdFromFormFields(fields, `QES_${fromNode.options.group}`);
                    const toIdField = getFieldIdFromFormFields(fields, `QES_${toNode.options.group}`);
                    const facetsValues = [{
                      champ: fromIdField,
                      strategie: fromNode.id,
                    }];
                    if (fromIdField === toIdField) {
                      // Si jamais l'ID de champ est le même pour les deux noeuds,
                      // on colle les deux valeurs dans un même tableau, qui sera
                      // découpé convenablement au moment du fetch des résultats
                      facetsValues[0].strategie = [fromNode.id, toNode.id];
                    } else {
                      // A l'inverse, si les ID de champs sont différents, on
                      // on ajoute une seconde "facetValue" avec les éléments
                      // du second noeud
                      facetsValues.push({
                        champ: toIdField,
                        strategie: toNode.id,
                      });
                    }
                    dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                  },
                }}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      series: {
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, `QES_${event.point.options.group}`),
                                  strategie: event.point.name,
                                },
                              ], (event.ctrlKey || event.metaKey)));
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      nodes: _.get(relations, '[0].nodes', []).map(
                        (node) => ({
                          ...node,
                          color: _.find(relationsNodesFields, { value: node.group })?.color,
                        }),
                      ),
                      data: _.get(relations, '[0].data', []).map(
                        (link) => ({
                          ...link,
                          color: link.color || _.find(relationsLinksFields, { value: link.type })?.color || '#c5c5c5',
                        }),
                      ),
                    }],
                    title: {
                      text: t('dashboard.widget.relations_graph'),
                      align: 'left',
                    },
                  },
                }}
              />
            );
          }}
        </WidgetContainer>
      </Grid>

    </Grid>
  );
};

export default DashboardChartsContainer;
