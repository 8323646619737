const primary = '#38939E';
const secondary = '#212529';

const themeConfig = {
  THEME: {
    custom: {
      logo: {
        height: '20px',
      },
      login: {
        logo: {
          height: '64px',
        },
      },
    },
    palette: {
      primary: {
        main: primary,
        transparent: `${primary}20`,
        dark: '#227781',
        attenuated: `${primary}80`,
      },
      secondary: {
        main: secondary,
        transparent: `${secondary}05`,
      },
      text: {
        neutral: '#212529',
      },
    },
  },
};

export default themeConfig;
